export const environment = {
  production: true,
  base_url: 'https://qld-canaldenuncias.azores.gov.pt/v1/',
  gra_id:
    'https://login.microsoftonline.com/14ab7718-3e71-4019-890a-54ed9b92f98a/oauth2/v2.0/authorize?response_type=code&scope=openid&client_id=8d8482d2-d37b-442f-8f8a-a0dd18d78e63&redirect_uri=https://qld-canaldenuncias.azores.gov.pt/portal/complaint',
  recaptcha: '6Ldu_ZkpAAAAAOVKT6Lx7V40qXcYCtf4_PfNvFqD',
  cmd: 'https://preprod.autenticacao.gov.pt/oauth/askauthorization?client_id=4813098104761502375&response_type=token&scope=http://interop.gov.pt/MDC/Cidadao/NIC%20http://interop.gov.pt/MDC/Cidadao/NomeProprio%20http://interop.gov.pt/MDC/Cidadao/DataValidade%20http://interop.gov.pt/MDC/Cidadao/NomeApelido&redirect_uri=http://qld-canaldenuncias.azores.gov.pt/portal',
  azorid:
    'https://qld-azorid.azores.gov.pt/auth/realms/azorid-dnxt/protocol/openid-connect/auth?client_id=portal-denunciante&redirect_uri=https://qld-canaldenuncias.azores.gov.pt/portal/complaint&response_type=code&scope=azi_email%20azi_fullName',
  azorid_enabled: true,
};
